import React, { Component } from "react";

import logo from './../../images/dompared-logo-dark.png';


class Header extends Component {
    render() {
        return (
            <div>
                <div className="container text-center p-5 header">
                    <div className="row">
                        <div className="col">

                            <h1 id="logo">
                                <img src={logo} alt="Dompared.com" width="500" height="auto" /><br />
                                <small>Finding the best deal for <strong>your</strong> domain</small>
                            </h1>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;
