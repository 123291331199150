import React, { Component } from "react";
import DomainForm from "./../domainForm/domainForm";

import hero from './../../images/dom-background.jpg';


class HomeContent extends Component {
  render() {

    var divStyle1 = { backgroundImage: "url("+ hero + ")", backgroundPosition: 'right', backgroundSize: 'cover', height: 600};
    var divStyle2 = { textAlign: 'left'};
    var divStyle3 = { textAlign: 'left', fontSize: 12, paddingTop: 50 };

    return (
      <div>
        <div className="text-center p-5" style={divStyle1}>
          <div className="row align-items-left">
            <div className="col-md-5">
              <h3 style={divStyle2}>
                We’ll find you the best price for your domain
              </h3>
              <p style={divStyle3}>
                <em>Simply enter the domain you need in the box below and we’ll compare deals for you</em>
              </p>
              <div>
                <DomainForm />
              </div>

            </div>
          </div>
        </div>

        <div className="container trust-icon-container text-center p-3">
          <div className="row">
            <div className="col-sm">
              <i className="fas fa-wrench trust-icon"></i> <p className="text-light">Our service is free</p>
            </div>
            <div className="col-sm">
              <i className="fas fa-balance-scale trust-icon"></i> <p className="text-light">We are completely impartial</p>
            </div>
            <div className="col-sm">
              <i className="fas fa-lock trust-icon"></i> <p className="text-light">We'll never sell your data</p>
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}

export default HomeContent;
