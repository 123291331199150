import React, { Component } from "react";

import logo from './../../images/dompared-alt-logo.png';


class Footer extends Component {
  render() {
    return (
      <div>
      <footer className="page-footer font-small blue pt-4 text-light p-5">


        <div className="container-fluid text-center text-md-left">


          <div className="row">


            <div className="col-md-6 mt-md-0 mt-3">


              <img className="pb-4" src={logo} alt="Dompared.com" width="250" height="auto" />
              <h5>About Us</h5>
              <p>Comparing all the leading registrars, Dompared.com finds you the best deal on your domain name. Price, service or extras that matter? We dompare for you.</p>

            </div>


            <hr className="clearfix w-100 d-md-none pb-3" />


            <div className="col-md-3 mb-md-0 mb-3">

              {/*  
              <h5>Important Information</h5>

              <ul className="list-unstyled">
                <li>
                  <a className="text-light" href="#!">Link 1</a>
                </li>
                <li>
                  <a className="text-light" href="#!">Link 2</a>
                </li>
                <li>
                  <a className="text-light" href="#!">Link 3</a>
                </li>
                <li>
                  <a className="text-light" href="#!">Link 4</a>
                </li>
              </ul>
              */}
            </div>
            <div className="col-md-3 mb-md-0 mb-3">


              <h5 className="text-uppercase">Latest From Us</h5>

              <ul className="list-unstyled">
                <li>
                  <a className="text-light" href="https://dompared.com/blog">Dompared.com Blog</a>
                </li>
                <li>
                  <a className="text-light" href="https://www.facebook.com/Dompared/">Follow Us On Facebook</a>
                </li>
                <li>
                  <a className="text-light" href="https://twitter.com/Dompared_com">Follow Us On Twitter</a>
                </li>
              </ul>

            </div>

          </div>


        </div>


      </footer>
      
  
      <div className="container-fluid bottom-bar">
        
        <div className="col-md-12 py-3">
          <p className="text-light pb-0 mb-0">© dompared.com</p>
        </div>
      
      </div>
        
     </div> 
    );
  }
}

export default Footer;
