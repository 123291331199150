import React, { Component } from "react";
import Table from "../table";
import Pagination from "../pagination/pagination";


//https://codepen.io/austinlyons/pen/YpmyJB

class ResultsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prices: [],
      currentPrices: [],
      providerOrder: true,
      priceOrder: true,
      currentPage: null,
      totalPages: null,
      pageLimit: null,
    };
    this.sortBy.bind(this);
  }

  onPageChanged = data => {
    const { prices } = this.state;
    const { currentPage, totalPages, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    const currentPrices = prices.slice(offset, offset + pageLimit);
    this.setState((state) => ({
      currentPrices : state.prices.slice(offset, offset + pageLimit)
    })); // you can't rely on ready the existing state - to update it - you have to pass the state into the function in setState
    this.setState({ currentPage, totalPages, pageLimit });
  };

  onPageChanged2( currentPage, totalPages, pageLimit) {
    const { prices } = this.state;
    const offset = (currentPage - 1) * pageLimit;
    const currentPrices = prices.slice(offset, offset + pageLimit);
    this.setState((state) => ({
      currentPrices : state.prices.slice(offset, offset + pageLimit)
    }));
    this.setState({ currentPage, totalPages, pageLimit });
  };

  compareByDesc(key) {
    if (key == 'price') {
      return function (a, b) {
        if (parseFloat(a[key]) > parseFloat(b[key])) return -1;
        if (parseFloat(a[key]) < parseFloat(b[key])) return 1;
        return 0;
      }; 
    } else {
      return function (a, b) {
        if (a[key] > b[key]) return -1;
        if (a[key] < b[key]) return 1;
        return 0;
      };
    }
  }
  
  compareByAsc(key) {
    if (key == 'price') {
      return function (a, b) {
        if (parseFloat(a[key]) < parseFloat(b[key])) return -1;
        if (parseFloat(a[key]) > parseFloat(b[key])) return 1;
        return 0;
      }; 
    } else {
      return function (a, b) {
        if (a[key] < b[key]) return -1;
        if (a[key] > b[key]) return 1;
        return 0;
      };
    }
  }

  initialSort() {
    const { priceOrder, providerOrder, currentPage, totalPages, pageLimit } = this.state;
    let arrayCopy = [...this.state.prices];
    arrayCopy.sort(this.compareByAsc('price'));
    this.setState({prices: arrayCopy});
    this.onPageChanged2(currentPage, totalPages, pageLimit);
  }

  sortBy(key) {
    const { priceOrder, providerOrder, currentPage, totalPages, pageLimit } = this.state;

    let arrayCopy = [...this.state.prices];
    
    if (key == 'price') {
      if ( priceOrder ) {
        arrayCopy.sort(this.compareByDesc(key));
      } else {
        arrayCopy.sort(this.compareByAsc(key));
      }
      this.setState(prevState => ({ priceOrder: !prevState.priceOrder }));
    }

    if (key == 'ProviderName') {
     
      if ( providerOrder ) {
        arrayCopy.sort(this.compareByDesc(key));
      } else {
        arrayCopy.sort(this.compareByAsc(key));
      }
      this.setState(prevState => ({ providerOrder: !prevState.providerOrder }));
    }
    console.log('array copy ' + arrayCopy[0].price);
    this.setState({prices: arrayCopy});
    this.setState({dummy: 'state changed'});
    console.log('state' + this.state.prices[0].price);
    this.onPageChanged2(currentPage, totalPages, pageLimit);

  }

  componentDidMount() {
   const { prices, totalRecords } = this.state;

   console.log("tld is " + this.props.tld);
   fetch("https://api.codetechs.co.uk/api/prices/tld/" + '.'+ this.props.tld)
    //fetch("http://localhost:8000/api/prices/tld/" + '.' + this.props.tld )
      .then(response => response.json())
      .then(data => {
        console.log({ data });
        this.setState(state => {
          const prices = [...state.prices, ...data.prices];
          console.log( prices );   
          return { prices };
        });
        this.initialSort();
      })
      .catch(error => {
        console.log({ error });
        let message = "Prices Error";
        this.setState({ message: message });
      });


  }
  render() {
    const { prices, currentPrices, providerOrder, priceOrder, dummy } = this.state;
    const totalRecords = prices.length;

    if (totalRecords === 0) return null;

    return (
        <div>
        
          <div className="row">
            <div className="col-8">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col" onClick={ this.sortBy.bind(this,'ProviderName')} style={{cursor: 'pointer'}} >Provider <div className={providerOrder ? "fa fa-chevron-up" : "fa fa-chevron-down"}></div></th>
                    <th scope="col" onClick={ () => this.sortBy('price')} style={{cursor: 'pointer'}}>Price (£) <div className={priceOrder ? "fa fa-chevron-up" : "fa fa-chevron-down"}></div></th>
                    <th scope="col">Term (in years)</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPrices.map(item => (
                    <tr key={item.id}>
                      <td>{item.ProviderName}</td>
                      <td>£{item.price}</td>
                      <td>{item.Term}</td>
                      <td><a href = {item.Link} className="btn btn-primary results-btn">get deal from {item.ProviderName} </a></td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div>
              <Pagination
                  totalRecords={totalRecords}
                  pageLimit={5}
                  pageNeighbours={1}
                  onPageChanged={this.onPageChanged}
                />
              </div>      
            </div>
          </div>
        </div>
    );
  }
}

export default ResultsTable;
