import React, { Component } from "react";
import Spinner from "./../spinner/spinner";
import { inject, observer } from "mobx-react";
import store from "../../stores/store";
import ResultsTable from "./../resultsTable/resultsTable";
import ResultsSorry from "./../resultsSorry/resultsSorry";

import hero from './../../images/dom-results-background.jpg';

@inject("Store")
@observer
class ResultsContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      message: [],
      tld: null,
      success: false,
      loaded: false
    };
  }

  componentDidMount() {
   
    fetch(
      "https://api.codetechs.co.uk/api/domains/registered/" +
      //"http://localhost:8000/api/domains/registered/" +
      this.props.match.params.domainName
    )
      .then(response => response.json())
      .then(data => {
        this.setState({ message: data.message });
        this.setState({ tld: data.TLD });
        this.setState({ success: data.success });
        this.setState({ loaded: true });
      })
      .catch(error => {
        console.log({ error });
        let message = "Registered Error";
        this.setState({ message: message });
      });
  }

  render() {
    const { Store, domainName } = this.props;
    const { message, tld, success, prices, loaded } = this.state;
    
    var divStyle1 = {backgroundImage: "url("+ hero + ")", backgroundPosition: 'right', backgroundSize: 'cover', height: 600};

    return (
      <div>
        {!loaded &&
        <div className="container p-5" >
          <div className="row p-2">
            <Spinner color="primary" text="Loading results" />
          </div>
        </div>
        }

        {success && 
        <div className="p-5" style={divStyle1}>
          <div className="row p-2">
              <div className="col">
                <h2>
                  Your quotes for {this.props.match.params.domainName}
                </h2>
              </div>
          </div>
            {tld && <ResultsTable tld={tld} />}
        </div>
        }
        
        {!success && loaded && 

            <ResultsSorry tld={tld} domainName={this.props.match.params.domainName}/>

        }
        
      </div>
    );
  }
}

export default ResultsContent;

/*
<h4>{this.props.match.params.domainName}</h4>
<div className="alert alert-primary" role="alert">
  {message}
</div>
<h1>{tld}</h1>
{tld && <ResultsTable tld={tld} />}

*/