import './spinner.css';
import React  from "react";


class Spinner extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
                <div className="spinner">
                <h2>{this.props.text}</h2> 
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
           );
    }
}

export default Spinner;
