import React, { Component } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter } from "react-router";
import { inject, observer } from "mobx-react";

//https://medium.com/@everdimension/how-to-handle-forms-with-just-react-ac066c48bd4f

@inject("Store")
@observer
class DomainForm extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      tld_found: false,
      submitted: false,
      dropdownOpen: false,
      domain: '',
    }
  }

  setDomain(event) {
    console.log(event);
    this.setState(prevState => ({ domain: prevState.domain + event }));
    this.setState({ submitted: false });
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  onChange(event) {
    this.setState({ domain: event.target.value });
  }

  handleSubmit(event) {

    event.preventDefault();

    const data = new FormData(event.target);
    const domain = data.get('domainName');

    if (!domain) {
      return;
    }

    this.setState({ submitted: true });

    console.log(domain);
    const index = domain.indexOf('.');
    if (index != -1) {
      const tld = domain.substr(index, domain.length);
      console.log(domain.indexOf('.'));
      this.props.history.push("/results/" + domain);
    }
  };

  render() {
    const { tld_found, submitted, domain } = this.state;

    return (
      <form className="form" onSubmit={this.handleSubmit}>
        <div className="input-group mb-3">
          <label htmlFor="inputDomain" className="sr-only">yourdomain.com</label>
          <input type="text" className="form-control domain-search" id="inputDomain" placeholder="yourdomain.com" name="domainName" value={domain} onChange={this.onChange.bind(this)} />
          <div className="input-group-append">

            {!submitted && <button className="btn btn-primary domain-search-button pulse" >Find me the best deal</button>}

            {submitted &&
              <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle caret color="warning">
                  Please select a domain
              </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={this.setDomain.bind(this, '.co.uk')}>.co.uk</DropdownItem>
                  <DropdownItem onClick={this.setDomain.bind(this, '.com')}>.com</DropdownItem>
                  <DropdownItem onClick={this.setDomain.bind(this, '.org')}>.org</DropdownItem>
                  <DropdownItem onClick={this.setDomain.bind(this, '.co')}>.co</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            }
          </div>
        </div>
      </form>
    );
  }
}

export default withRouter(DomainForm);

/*
https://www.leighhalliday.com/mobx-create-react-app-without-ejecting
onClick={e => {
  e.preventDefault;
  this.props.Store.toggleTheme();
}}


*/
