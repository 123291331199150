import React, { Component } from "react";
import Spinner from "./../spinner/spinner";



//https://codepen.io/austinlyons/pen/YpmyJB

class ResultsSorry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tlds: [],
      searching_tlds: true,
      found_alternative: false,
    };
  }

  componentDidMount() {
    const { tlds } = this.state;
    const { tld, domainName } = this.props;
    var domainNameStripped = domainName.substring(0, domainName.indexOf('.'));

    fetch(
      "https://api.codetechs.co.uk/api/prices/tld"
    )
      .then(response => response.json())
      .then(data => {

        var registered = [];

        for (var k in data.tlds) {
          var _tld = '.' + tld; 
          if (_tld != data.tlds[k]) {// dont save the existing one
            var obj = { 'tld': data.tlds[k], 'notRegistered': false };
            registered[k] = obj;
            this.isRegistered(domainNameStripped + data.tlds[k], k);
          }
        }
        this.setState({
          tlds: [...this.state.tlds, ...registered]
        })

      })
      .catch(error => {
        let message = "Registered Error";
        this.setState({ message: message });
      });

  }

  handleClick(domain) {
    this.props.history.push("/results/" + domain);
  }

  isRegistered(domainName, key) {
    fetch(
      "https://api.codetechs.co.uk/api/domains/registered/" + domainName
    )
      .then(response => response.json())
      .then(data => {
        if (key == this.state.tlds.length - 1) {
          this.setState({ searching_tlds: false });
        }
        if (data.success) {
          var found_alternative = false;
          this.setState(prevState => {
            const temp_state = prevState;
            const tlds = prevState.tlds.map((item, k) => {
              if (key == k) {
                item.notRegistered = true;
                found_alternative = true;
              }
              return item; // return from map
            }); // end of map
            return { // end of setstate
              tlds,
            }
          }, () => { // the setste call back !
            this.setState({ found_alternative: found_alternative })
          })
        }
      })
      .catch(error => {
        let message = "Registered Error";
        this.setState({ message: message });
      });
  }

  
  getBaseUrl() {
    return window.location.origin;
  }

  render() {
    const { tlds, searching_tlds, found_alternative } = this.state;
    var domainName = this.props.domainName;
    var domainNameStripped = domainName.substring(0, domainName.indexOf('.'));

    return (
      <div id='baseresult'>
        <div className="container p-5">
          <div className="row p-2">
            <h2>Sorry, but it looks like {domainName} has been registered.</h2>

            {searching_tlds &&

              <div>
                <Spinner color="primary" text="Looking for alternatives" />
              </div>

            }
            {!searching_tlds && found_alternative &&

              <div>
                <h2>How about these alternatives ?</h2>
              </div>

            }

          </div>
          <table className="tld-table">

            <tbody>
              {tlds.map(item => (
                <tr key={item.tld}>
                  {item.notRegistered &&
                    <td><i className="fas fa-check-circle check-icon"></i></td>
                  }
                  {!item.notRegistered &&
                    <td><i className="fas fa-times-circle check-icon"></i></td>
                  }
                  <td>{domainNameStripped}{item.tld}</td>
                  {item.notRegistered &&
                    <td>
                      <a href={this.getBaseUrl() + '/results/' + domainNameStripped + item.tld} className="btn btn-primary domain-search-button pulse">Find me the best deal</a>
                    </td>
                  }
                </tr>
              ))}
            </tbody>

          </table>

          <div className="row p-2">
            <a href={this.getBaseUrl()} className="btn btn-primary">Try a different domain</a>
          </div>
        </div>
      </div>
    );
  }
}
export default ResultsSorry;
