import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";
import "./App.css";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import HomeContent from "./components/homeContent/homeContent";
import ResultsContent from "./components/resultsContent/resultsContent";

class App extends Component {
  render() {
    return (
      <div>
        <Header />
        <div>
            <Switch>
                <Route exact path="/" component={HomeContent} />  
                <Route path="/results/:domainName" component={ResultsContent} />
              </Switch>
        </div>
        <div>
          <Footer/>
        </div>
      </div>
    );
  }
}

export default App;
